<template>
    <div>
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="充值申请" name="newlybuild">
                <div class="heder_top">
                    <el-button type="warning" size="small">保存</el-button>
                    <el-button type="warning" size="small">删除</el-button>
                </div>
                
      <div class="ReceiptForm">
        <el-form ref="form" label-position="left" :model="form" label-width="90px" class="formbox">
          <el-form-item label="充值编号">
            <el-input v-model="form.name" class="inputstyle"></el-input>
          </el-form-item>
          <el-form-item label="会员号">
            <el-input v-model="form.name" class="inputstyle"></el-input>
          </el-form-item>
          <el-form-item label="*请选择银行">
          <el-select v-model="form.transportvalue" class="inputstyle" placeholder="请选择">
            <el-option
              v-for="item in form.transport"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
          <el-form-item label="充值金额">
            <el-input v-model="form.name"  class="inputstyle"></el-input>
          </el-form-item>
          <el-form-item label="流水号">
            <el-input v-model="form.name" class="inputstyle"></el-input>
          </el-form-item>
          
          
         <el-form-item label="充值描述">
            <el-input v-model="form.name" type="textarea" class="inputstyle"></el-input>
          </el-form-item>
        </el-form>
      </div>
       
    </el-tab-pane>
    <el-tab-pane label="充值处理" name="handle">
      <div class="heder_top">
                    <el-button type="warning" size="small">保存</el-button>
                    <el-button type="warning" size="small">删除</el-button>
                </div>
      
      <div class="ReceiptForm">
        <el-form ref="form" label-position="left" :model="form" label-width="90px" class="formbox">
          <el-form-item label="充值编号">
            <el-input v-model="form.name" class="inputstyle"></el-input>
          </el-form-item>
         
         
         <el-form-item label="*充值状态">
          <el-select v-model="form.transportvalue" class="inputstyle" placeholder="请选择">
            <el-option
              v-for="item in form.transport"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
         </el-form-item> 
         <el-form-item label="描述">
            <el-input v-model="form.name" type="textarea" class="inputstyle"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-tab-pane>
    </el-tabs>
    <div class="picture">
         <div class="picturetop">
           <div class="see"></div>
           <div class="uploid">
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
           </div>

         </div>
         <div class="picturebotton">查看图片</div>
       </div>
 </div>
</template>
<script>
export default {
    data(){
        return{                                
          form: {                                     //新建异常
            name:'',  
            transportvalue:'',                                
            transport:[                               //运输方式
            {
              value: "选项1",
              label: "运输方式1",
            },
            {
              value: "选项2",
              label: "运输方式2",
            },
           ],
          },
            couriernumber:'',                   //快递单号查询
            activeName: 'newlybuild',           //头部切换
            dialogImageUrl: '',                 //图片
            dialogVisible: false,               //图片
        }
    },
    methods:{
      //图片
      handleRemove(file, fileList) {
        ////console.log(file, fileList);
      },
      //图片
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    }
}
</script>
<style scoped lang="scss">
.heder_top{
    width: 100%;
    padding:10px 20px;
    box-sizing: border-box;
}
.ReceiptForm {
  min-width: 500px;
  width: 50%;
  padding: 30px 30px 30px 100px;
  box-sizing: border-box;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  .inputstyle{
    width: 70%;
  }
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .picture{
    width: 98%;
    margin: 0 auto;
    padding: 30px;
    box-sizing: border-box;
    .picturetop{
      width: 100%;
      padding: 10px;
      display: flex;
      .see{
        min-width: 150px;
        height: 150px;
        border: 1px solid #797979;
        margin-left: 100px;
      }
      .uploid{
        width: 1000px;
        height: 180px;
        
        margin-left: 40px;
        display: flex;
      }
    }
    .picturebotton{
      width: 100%;
      padding: 20px;
      margin-left: 125px;
    }
  }
</style>